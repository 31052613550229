import React, {
    useEffect,
    useState
} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import {
    useParams,
    useNavigate
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


const JoinPage = ({ backendUrl, sessionPath, resolveSubPath }) => {

    let navigate = useNavigate();
    let joinCode = useParams().joinCode;


    const [textFieldInput, setTextFieldInput] = useState("")
    const [isLoading, setIsLoading] = useState(false)


    function handleTextfieldChange(event) {
        var input = event.target.value
        input = input.toUpperCase()
        input = input.substring(0, 4)
        setTextFieldInput(input);
    };

    useEffect(() => {
        if (joinCode !== undefined) {
            setTextFieldInput(joinCode)
        }
    }, [joinCode]);


    useEffect(() => {
        async function moveToChallengePage() {
            try {
                setIsLoading(true)
                await resolveJoinCode()
            } catch (error) {
                toast.error(error);
            }
            finally {
                setIsLoading(false)
            }
        }
        if (isCodeComplete()) {
            moveToChallengePage()
        }
        // eslint-disable-next-line
    }, [textFieldInput]);


    async function resolveJoinCode() {
        var response = await fetch(backendUrl + sessionPath + resolveSubPath, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                joinCode: textFieldInput,
            })
        })


        const data = await response.text()

        if (!response.ok) {
            throw await data
        }


        // change to challenge page
        const challengeUrl = sessionPath + "/" + data
        navigate(challengeUrl);

    }

    function isCodeComplete() {
        return textFieldInput.length === 4
    }



    return (
        <>
            <ToastContainer />

            <h1>Join existing session</h1>
            <p>
                Please enter your 4-character code.
            </p>


            <TextField
                label={"Code"}
                autoFocus={true}
                value={textFieldInput}
                onChange={handleTextfieldChange}
                fullWidth
            />




            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={(isLoading)}
            >
                <CircularProgress color="inherit" />

            </Backdrop>


        </>
    )
}

export default JoinPage;
