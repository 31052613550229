import React, {
    useState,
    useEffect
} from 'react'; import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import LoadingButton from '@mui/lab/LoadingButton';
import { ToastContainer, toast } from 'react-toastify';
import './Voting.css';

const Voting = ({ sessionDetails, setSessionDetails, sessionId, questions, selectedTab, backendUrl, sessionPath, voteSubPath, username }) => {

    const [before, setBefore] = useState(undefined)
    const [unsavedChanges, setUnsavedChanges] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        async function initializeSlider() {
            const values = getBeforeValues()
            setBefore(values)
            setUnsavedChanges(JSON.parse(JSON.stringify(values)))
        }
        initializeSlider()
        // eslint-disable-next-line
    }, [selectedTab, sessionDetails]);

    function updateVoting(questionId, newValue) {
        var unsaved = JSON.parse(JSON.stringify(unsavedChanges))
        unsaved.vote[questionId] = newValue
        setUnsavedChanges(unsaved)
    }

    function updateComment(newValue) {
        var unsaved = JSON.parse(JSON.stringify(unsavedChanges))
        unsaved.comment = newValue
        setUnsavedChanges(unsaved)
    }


    function getBeforeValueForQuestion(identifier) {
        if (before === undefined || before.vote === undefined) {
            return 0
        } else {
            return before.vote[identifier]
        }
    }

    function getBeforeValues() {
        const answersFromFetchedData = getAnswersFromFetchedData()
        if (answersFromFetchedData === undefined) {
            //console.log("no voting yet")
            const emptyScaffold = generateEmptyScaffold()
            setUnsavedChanges(emptyScaffold)
            return emptyScaffold
        } else {
            //console.log("voting found")
            return answersFromFetchedData
        }
    }
    function generateEmptyScaffold() {
        const alphabet = "abcdefghijklmnopqrstuvwxyz"

        var emptyVotes = {}
        for (let index = 0; index < questions.length; index++) {
            emptyVotes[alphabet.charAt(index)] = 0
        }
   
        return {
            vote: emptyVotes,
            comment: ""
        }
    }


    function getAnswersFromFetchedData() {

        const voteResult = sessionDetails.votes.filter((a) => a.username === username && a.itemNumber === selectedTab)[0]

        if (voteResult === undefined) {
            return undefined
        }
        else{
            return {
                vote: voteResult.vote === undefined ? undefined : voteResult.vote,
                comment: voteResult.comment === undefined ? "" : voteResult.comment,
            }
        }
    }

    async function saveChanges() {
        try {
            setIsLoading(true)
            await postNewVotes()

            setBefore(unsavedChanges)
        } catch (error) {
            toast.error(error);
        }
        finally {
            setIsLoading(false)
        }
        return
    }

    async function postNewVotes() {

        const item = {
            sessionId: sessionId,
            username: username,
            itemNumber: selectedTab,
            vote: unsavedChanges.vote,
            comment: unsavedChanges.comment
        }

        var response = await fetch(backendUrl + sessionPath + voteSubPath, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(item)
        })

        if (!response.ok) {
            throw await response.text()
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        setSessionDetails(data)
    }

    return (
        <>
            <ToastContainer />
            {questions.map((question) => (
                <div key={question.id}>
                    <p>
                        <u>
                            {question.category}
                        </u>
                        <br />
                        {question.question}
                    </p>
                    {question.input.type === "scale" &&
                        <>
                            <Slider
                                key={selectedTab + "-" + question.id + "-" + getBeforeValueForQuestion(question.id)}
                                defaultValue={getBeforeValueForQuestion(question.id)}
                                onChange={(event, newValue) => updateVoting(question.id, newValue)}
                                valueLabelDisplay="auto"
                                step={1}
                                min={0}
                                max={question.input.length}
                            // marks={[
                            //     {
                            //         value: 0,
                            //         label: question.input.startLabel,
                            //     },
                            //     {
                            //         value: question.input.length,
                            //         label: question.input.endLabel,
                            //     }
                            // ]}
                            />
                            <div id="textbox">
                                <div className='left'>
                                    <h6 className='no-margin'>{question.input.startLabel}</h6>
                                </div>
                                <div className='right'>
                                    <h6 className='no-margin'>{question.input.endLabel}</h6>
                                </div>
                                <br />
                                <br />
                            </div>
                        </>
                    }
                </div>
            ))}

            <div>
                <p>
                    <u>
                        Comment (Optional)
                    </u>
                    <br />
                    Note that this comment is public and can be seen by others. It can only consist out of maximum 100 characters.
                    <br />
                    <textarea
                     style={{ width: "100%" }} 
                     onChange={(area) => updateComment(area.target.value)} 
                     value={unsavedChanges? unsavedChanges.comment:""}
                     maxLength="100">
                    </textarea>
                    {/* <input type="text" id={"comment" + selectedTab} style={{ 'box-sizing: border-box'}} /> */}

                </p>
            </div>

            {isLoading &&
                <>
                    <LoadingButton fullWidth loading variant="outlined">
                        Submit
                    </LoadingButton>
                </>
            }
            {!isLoading &&
                <>
                    <Button variant="outlined"
                        fullWidth
                        disabled={JSON.stringify(before) === JSON.stringify(unsavedChanges)}
                        onClick={() => {
                            saveChanges()
                        }}
                    >Save changes</Button>
                </>
            }

            <br />
            <br />

        </>
    )
}

export default Voting;
