import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Summary = ({ sessionDetails, isRefreshingSessionDetails, currentPreset }) => {

    const questions = currentPreset.questions

    function maxPoints() {
        var total = 0
        questions.forEach(question => {
            total += question.input.length
        });
        return total
    }

    function calculatePerPersonTotal(username, wineId) {
        const vote = sessionDetails.votes.filter((vote) => (vote.username === username && wineId === vote.itemNumber))[0]
        // console.log(JSON.stringify(vote))
        var total = 0
        Object.keys(vote.vote).forEach(function (key) {
            // console.log('Key : ' + key + ', Value : ' + data[key])
            total += vote.vote[key]
        })
        return total
    }

    function rows() {
        const tableData = []
        for (let index = 1; index <= sessionDetails.numberOfTasting; index++) {
            const row = {}
            row.index = index
            row.wineId = currentPreset.subject + " #" + index
            const relevantVotes = sessionDetails.votes.filter((vote) => vote.itemNumber === index)
            row.relevantVotes = relevantVotes
            var rowTotal = 0
            // eslint-disable-next-line
            questions.forEach(question => {
                var value;
                if (relevantVotes[0] === undefined) {
                    value = 0
                } else {
                    //calculate avg for each question
                    var voteSum = 0

                    relevantVotes.forEach(vote => {
                        voteSum += vote.vote[question.id]
                    });
                    value = Math.round((voteSum / relevantVotes.length) * 100) / 100
                }
                row[question.id] = value
                rowTotal += value
            });
            row.total = rowTotal

            // console.log(row)
            tableData.push(row)
        }
        //console.log(tableData)
        return tableData
    }




    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (<>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    {
                        row.relevantVotes[0] !== undefined &&
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }

                </TableCell>
                <TableCell scope="row">
                    {row.wineId}
                </TableCell>
                {questions.map((question) => (
                    <TableCell key={question.id} >{row[question.id]}</TableCell>
                ))}
                <TableCell >{row.total}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={questions.length+4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Individual Votes
                            </Typography>
                            <Table aria-label="purchases" style={{backgroundColor:"#f2f5f3"}}>
                                <TableHead>
                                    <TableCell />

                                    <TableCell>User</TableCell>
                                    {/* <TableCell>Wine #</TableCell> */}

                                    {questions.map((question) => (
                                        <TableCell key={question.id}>{question.category} (max. {question.input.length})</TableCell>
                                    ))}
                                    <TableCell>Total (max. {maxPoints()})</TableCell>
                                    <TableCell>Comments</TableCell>

                                </TableHead>
                                <TableBody>
                                    {row.relevantVotes[0] !== undefined &&

                                        <>
                                            {
                                                row.relevantVotes.map((relevantVote) => (
                                                    <TableRow key={row.wineId + relevantVote.username}>
                                                        <TableCell />
                                                        <TableCell >{relevantVote.username}</TableCell>
                                                        {/* <TableCell component="th" scope="row">
                                                {row.wineId}
                                            </TableCell> */}
                                                        {questions.map((question) => (
                                                            <TableCell key={question.id} >{relevantVote.vote[question.id]}</TableCell>
                                                        ))}
                                                        <TableCell >{calculatePerPersonTotal(relevantVote.username, row.index)}</TableCell>
                                                        <TableCell >{relevantVote.comment}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
        );
    }

    return (
        <>
            {isRefreshingSessionDetails &&
                <CircularProgress color="inherit" />
            }
            {!isRefreshingSessionDetails &&

                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{currentPreset.subject} #</TableCell>

                                {questions.map((question) => (
                                    <TableCell key={question.id}>{question.category} (max. {question.input.length})</TableCell>
                                ))}
                                <TableCell>Total (max. {maxPoints()})</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows().map((row) => (
                                <Row key={row.name} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {/* {JSON.stringify(sessionDetails)} */}
        </>

    )
}

export default Summary;
