import React, {
    useState,
    useEffect
} from 'react';
import {
    useParams
} from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Voting from '../../components/Voting';
import Summary from '../../components/Summary';


const SessionPage = ({ backendUrl, sessionPath, presets, voteSubPath }) => {
    let sessionId = useParams().sessionId;

    const [username, setUsername] = useState("")
    const [isUsernameConfirmed, setIsUsernameConfirmed] = useState(false)
    const localStorageUsernameKey = "oishi-username"

    const [isSessionValid, setIsSessionValid] = useState(undefined)
    const [sessionDetails, setSessionDetails] = useState(undefined)
    const [isRefreshingSessionDetails, setIsRefreshingSessionDetails] = useState(false)


    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };


    function currentPreset() {
        // console.log(presets)
        // console.log(sessionDetails.presetSelection)
        // console.log(presets.filter((preset) => preset.id === sessionDetails.presetSelection))
        return presets.filter((preset) => sessionDetails.presetSelection === preset.id)[0]
    }

    function confirmUsername() {
        setUsername(username.trim())
        writeCachedUsername()
        setIsUsernameConfirmed(true)
    }
    function isValidUsername() {
        if (username !== "") {
            return false
        }
        return username.trim() !== ""
    }

    function readCachedUsername() {
        return localStorage.getItem(localStorageUsernameKey)
    }

    function writeCachedUsername() {
        localStorage.setItem(localStorageUsernameKey, username);
    }
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
            key: index
        };
    }

    useEffect(() => {
        async function checkForExistingUsername() {
            const cachedUsername = readCachedUsername()
            if (cachedUsername !== null) {
                setUsername(cachedUsername)
            }
        }
        checkForExistingUsername()
    }, []);

    useEffect(() => {
        async function getSessionInfo() {
            try {
                setIsRefreshingSessionDetails(true)

                var response = await fetch(backendUrl + sessionPath + "/" + sessionId, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                if (!response.ok) {
                    setIsSessionValid(false)
                    const data = await response.text()
                    throw await data
                }

                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();


                setIsSessionValid(true)
                setSessionDetails(data)

            } catch (error) {

            } finally {
                setIsRefreshingSessionDetails(false)
            }
        }


        if (selectedTab === 0) {
            //console.log("refreshing session details")
            getSessionInfo()
        }
        else {
            //console.log("skip refreshing session details")
        }

        // eslint-disable-next-line
    }, [selectedTab]);

    return (
        <>
            {isSessionValid === undefined &&
                <>
                    <h1>
                        Loading Session Information.
                        <br />
                        <CircularProgress color="inherit" />
                    </h1 >
                </>
            }
            {isSessionValid === false &&
                <h1>
                    Session ID is invalid.
                </h1 >
            }
            {isSessionValid === true &&
                <>
                    {isUsernameConfirmed === false &&
                        <>
                            <p>
                                Please enter your name
                            </p>


                            <TextField
                                label={"Name"}
                                autoFocus={true}
                                value={username}
                                onChange={(event) => { setUsername(event.target.value) }}
                                fullWidth
                            />

                            <br /><br />

                            <Button variant="outlined"
                                onClick={() => confirmUsername()}
                                disabled={isValidUsername()}
                            >Start</Button>
                        </>
                    }
                    {isUsernameConfirmed === true &&
                        <>
                            <h1>Welcome {username} to {sessionDetails.sessionName}.</h1>
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons={true}
                                allowScrollButtonsMobile
                                sx={{
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                    },
                                }}
                            >
                                {
                                    Array.from({ length: sessionDetails.numberOfTasting+1 }, (_, k) => (
                                        <Tab label={k === 0 ? "Overview" : currentPreset().subject + " #" + k} {...a11yProps(k)} />
                                    ))
                                }

                            </Tabs>

                            {selectedTab === 0 &&
                                <Summary
                                    isRefreshingSessionDetails={isRefreshingSessionDetails}
                                    sessionDetails={sessionDetails}
                                    currentPreset={currentPreset()}
                                />
                            }
                            {selectedTab > 0 &&
                                <Voting
                                    sessionDetails={sessionDetails}
                                    setSessionDetails={setSessionDetails}
                                    sessionId={sessionId}
                                    backendUrl={backendUrl}
                                    sessionPath={sessionPath}
                                    voteSubPath={voteSubPath}
                                    username={username}
                                    questions={currentPreset().questions}
                                    selectedTab={selectedTab}
                                />
                            }
                        </>
                    }
                </>
            }
        </>
    )
}

export default SessionPage;
