import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

const WelcomePage = () => {
    let navigate = useNavigate();

    function jumpToCreateSession() {
        navigate('/create');
    }
    function jumpToJoinSession() {
        navigate('/join');
    }

    return (
        <div >
            <h1>Oishī</h1>
            <h6>[jap.: tasty, delicious, juicy]</h6>
            <p>
                The new way of online blindtasting experience. Grab your friends and find out what is really tasty (or not)!
            </p>

            <Button variant="outlined" onClick={() => jumpToCreateSession()}>Create new session</Button>
            <br /><br />
            <Button variant="outlined" onClick={() => jumpToJoinSession()}>Join existing session</Button>

        </div>
    )
}

export default WelcomePage;
