import React from 'react';
import './footer.css';
const Footer = () => {
    return (
        <div className="footer">

            <p>
                An app made with ♥️ by  &nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://carlo-hildebrandt.de">Carlo</a>
            </p>
        </div>
    )
}

export default Footer;
