import React, {
  useState,
  useEffect
} from 'react';
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import WelcomePage from './content/WelcomePage';
import SessionPage from './content/SessionPage';
import CreatePage from './content/CreatePage';
import JoinPage from './content/JoinPage';
import NotFoundPage from './content/NotFoundPage';
import Footer from './components/Footer';



function App() {

  const theme = createTheme({
    typography: {
      fontFamily: "Rubik",
      button: {
        textTransform: 'none'
      }
    }
  });

  //global configuration
  const backendUrl = "https://oishi-backend.carlo-hildebrandt.de"
  const presetsPath = "/presets"
  const sessionPath = "/session"
  const resolveSubPath = "/resolve"
  const voteSubPath ="/vote"

  //global variables
  const [presets, setPresets] = useState(undefined)


  //global effect
  useEffect(() => {
    async function fetchPresets() {
      const res = await fetch(backendUrl + presetsPath)
      var data = await res.json()
      setPresets(data)
    }
    fetchPresets()
  }, [backendUrl, presetsPath]);
  return (
    <ThemeProvider theme={theme}>
      <div className='inner-container'>
        <BrowserRouter>
          <Routes>
            <Route path="/" exact={true} element={<WelcomePage />} />
            <Route path="/session/:sessionId" element={<SessionPage
              backendUrl={backendUrl}
              sessionPath={sessionPath}
              presets={presets}
              voteSubPath={voteSubPath}
            />} />
            <Route path="/create" element={<CreatePage
              backendUrl={backendUrl}
              presets={presets}
              sessionPath={sessionPath}
            />} />
            <Route path="/join" exact={true} element={<JoinPage
              backendUrl={backendUrl}
              sessionPath={sessionPath}
              resolveSubPath={resolveSubPath}
            />} />
            <Route path="/join/:joinCode" element={<JoinPage
              backendUrl={backendUrl}
              sessionPath={sessionPath}
              resolveSubPath={resolveSubPath}
            />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </ThemeProvider>

  );
}

export default App;
