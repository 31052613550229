import React, {
    useEffect,
    useState
} from 'react';
import { useNavigate } from "react-router-dom";


const NotFoundPage = () => {
    let navigate = useNavigate();
    const [countdown, setCountdown] = useState(5)

    useEffect(() => {
        const interval = setInterval(() => {
            const newCount = countdown - 1
            if (newCount < 0) {
                returnHome()
            } else {
                setCountdown(newCount)
            }
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [countdown]);

    function returnHome() {
        navigate('/');
    }


    return (
        <div className='centered'>
            <h1>404</h1>
            <h4>Site not found. Returning to safe place in {countdown}.</h4>
        </div>
    )
}

export default NotFoundPage;
