import React, {
    useState
} from 'react';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreatePage = ({ backendUrl, presets, sessionPath }) => {

    const [nameInput, setNameInput] = useState("")
    const [presetValue, setPresetValue] = useState("")
    const [numberInput, setNumberInput] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [createdSession, setCreatedSession] = useState(undefined)


    async function createNewSession() {
        try {
            setIsLoading(true)
            await postNewSession()
        } catch (error) {
            toast.error(error);
        }
        finally {
            setIsLoading(false)
        }
    }


    async function postNewSession(name) {
        var response = await fetch(backendUrl + sessionPath, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                sessionName: nameInput,
                presetSelection: parseInt(presetValue),
                numberOfTasting: parseInt(numberInput),
                creationDate: new Date().toLocaleDateString('de-DE')
            })
        })

        if (!response.ok) {
            throw await response.text()
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        setCreatedSession(data)
    }


    function handleNumberInput(input) {
        if (!isNaN(input)) {
            setNumberInput(input)
        }
    }

    function isReadyForSubmission() {
        return ((nameInput !== "") && (presetValue !== "") && (numberInput > 0))
    }


    return (
        <>
            <ToastContainer />

            <h1>Create a new session</h1>

            {createdSession === undefined &&
                <>
                    <p>
                        <u>
                            Give that blindtasting journey a name
                        </u>
                    </p>


                    <TextField
                        label={"Name"}
                        autoFocus={true}
                        value={nameInput}
                        onChange={(event) => { setNameInput(event.target.value) }}
                        fullWidth
                        disabled={isLoading}
                    />

                    <br /><br />
                    <p>
                        <u>
                            What are you tasting?
                        </u>
                    </p>


                    {presets === undefined &&
                        <>
                            <Skeleton variant="rectangular" height={30} />
                            <br />
                            <Skeleton variant="rectangular" height={30} />
                        </>
                    }


                    {presets !== undefined &&
                        <>
                            <FormControl>
                                <RadioGroup
                                    value={presetValue}
                                    onChange={(event) => { setPresetValue(event.target.value) }}>

                                    {presets.map((preset) => (
                                        <FormControlLabel
                                            key={preset.id}
                                            value={preset.id}
                                            control={<Radio />}
                                            label={preset.type}
                                            disabled={isLoading}
                                        />
                                    ))}

                                </RadioGroup>
                            </FormControl>
                        </>
                    }


                    <br /><br />
                    <p>
                        <u>
                            Select number of things to be tasted
                        </u>
                    </p>



                    <TextField
                        label={"Number"}
                        value={numberInput}
                        onChange={(event) => { handleNumberInput(event.target.value) }}
                        fullWidth
                        disabled={isLoading}
                    />
                    <br /><br />

                    <Button variant="outlined"
                        onClick={() => createNewSession()}
                        disabled={(!isReadyForSubmission() || isLoading)}
                    >Create new session</Button>
                </>
            }

            {createdSession !== undefined &&
                <>
                    <p>
                        Successfully created the new session.

                        Join using the code:
                    </p>
                    <h1>
                        {createdSession.joinCode}
                    </h1>

                    <p>
                        Or share the following link with your friends:
                    </p>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={window.location.protocol + "//" + window.location.hostname + "/join/" + createdSession.joinCode}>
                        {window.location.protocol + "//" + window.location.hostname + "/join/" + createdSession.joinCode}
                    </a>


                </>
            }

        </>
    )
}

export default CreatePage;
